.c-feature-box {
  position: relative;
  padding: 20px;

  &__title {
    font-size: 20px;
    color: #161616;
    margin-bottom: 10px;
  }

  &__text {
    color: #666666;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
}