#easy-to-start {
  background-image: url('../images/easy-to-start.png');
  @media
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) {
    background-image: url('../images/easy-to-start@2x.png');
  }
}

#carefully-thought-ui {
  background-image: url('../images/ui.png');
  @media
  (-webkit-min-device-pixel-ratio: 2), 
  (min-resolution: 192dpi) {
    background-image: url('../images/ui@2x.png');
  }
}