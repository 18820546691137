.c-belt {
  position: relative;
  display: block;
  height: 500px;
  margin-left: -120px;
  margin-right: -120px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: #fafafa;
  margin-bottom: 100px;
  background-size: cover;
  background-position: center center;
  overflow: visible;

  &__callout {
    position: absolute;
    width: 380px;
    height: 400px;
    left: 120px;
    bottom: -100px;
    color: #fff;
    font-size: 16px;
    line-height: 1.8;
    padding: 50px 40px;
    background-color: $color-primary;
    z-index: 15;
  }

  &__title {
    color: #fff;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 16px;
    font-weight: 300;
  }

  &__content {

  }
}

.c-belt.c-belt--end {
  .c-belt__callout {
    left: auto;
    right: 120px;
  }
}

.c-belt.c-belt--dark {
  .c-belt__callout {
    color: #333;
  }
  .c-belt__title {
    color: #333;
  }
}

@media (max-width: 1200px) {
  .c-belt {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    &__callout {
      left: 0;
      width: 100%;
    }
  }
  .c-belt.c-belt--end {
    .c-belt__callout {
      left: auto;
      right: 0;
    }
  }
}