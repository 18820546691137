.c-feature-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 600px;
  @include clearfix;
  li {
    position: relative;
    color: #888;
    line-height: 1.2;
    font-size: 16px;
    margin-bottom: 16px;
    padding-left: 16px;
    width: 300px;
    float: left;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -4px;
      width: 8px;
      height: 8px;
      background-color: $color-primary;
    }
    &:nth-child(2n+1) {
      clear: left;
    }
  }
}

@media (max-width: 950px) {
  .c-feature-list {
    width: 100%;
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    li {
      width: 50%;
      font-size: 14px;
    }
  }
}