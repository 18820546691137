.app {

}

.app-topbar {
  padding: 20px 0;

  .navbar {
    &__item {
      &:hover {
        color: $color-primary;
      }
    }
    &__btn {
      display: block;
      color: $color-primary;
      border: 2px solid $color-primary;
      line-height: 20px;
      padding: 6px 20px;
      border-radius: 20px;
      transition: all 150ms ease;
      &:hover {
        color: #fff;
        background-color: $color-primary;
      }
    }
  }
}

.app-billboard {
  position: relative;
  margin-bottom: 40px;
  &__particles {
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__content {
    @include clearfix;
  }
    &__callout {
      float: left;
      padding-top: 150px;
      width: 280px;
      h1 {
        font-size: 24px;
        color: #161616;
        line-height: 30px;
        margin-bottom: 30px;
        font-family: 'open sans', sans-serif;
        font-weight: 400;
      }

      .btn {
        font-size: 16px;
        line-height: 22px;
        padding: 8px 16px;
        height: auto;
      }
      @media (max-width: 1180px) {
        float: none;
        width: 100%;
        padding-top: 30px;
        text-align: center;
      }
    }
    &__preview {
      float: right;
      margin-right: -40px;
      width: 900px;
      height: 550px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../images/preview.png');
      @media
      (-webkit-min-device-pixel-ratio: 2), 
      (min-resolution: 192dpi) {
        background-image: url('../images/preview@2x.png');
      }
      @media (max-width: 1180px) {
        float: none;
        margin-right: 0;
        width: 100%;
        height: auto;
        padding-bottom: 61.11111111%;
      }
    }
}

.app-footer {
  position: relative;
  padding-top: 30px;
  margin-top: 120px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 13px;
  color: #888;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -40px;
    width: 80px;
    height: 1px;
    background-color: #e5e5e5;
  }
  p {
    margin: 0;
  } 
}